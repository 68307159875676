import { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useRequest } from '../../../hooks';
import { apiRoutes } from "../../../api";
import VersionApp from "../../version-app";

const ChangePasswordForm = () => {
  const navigate = useNavigate();
  const [changePasswordFn, { data, error, isLoading }] = useRequest({
    url: apiRoutes.CHANGE_CRED,
    method: 'PUT',
  });
  const [showMsgError, setShowMsgError] = useState(false);
  const [msgError, setMsgError] = useState([]);

  const { 
    register, 
    handleSubmit, 
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    changePasswordFn({
      correo: data.userName,
      contrasenia: data.password,
      nuevaContrasenia: data.newPassword,
    });
  };

  useEffect(() => {
    if (data) {
      navigate("/");
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setShowMsgError(true);
      if (error.error.response && error.error.response.data && error.error.response.data.errorDetail)
        setMsgError(error.error.response.data.errorDetail);
    }
  },[error]);

  return (
    <main className="form-signin w-100 h-100 m-auto d-flex justify-content-center align-items-center">
      <div className="wrapper m-auto form-card">
        {showMsgError && <Alert variant='danger' onClose={() => setShowMsgError(false)} dismissible>{msgError.map(msg => <p>{msg}</p>)}</Alert>}
        <form id="loginform" onSubmit={handleSubmit(onSubmit)}>
          <div className={errors.userName ? 'form-control is-invalid mb-3': 'form-control mb-4'}>
            <label htmlFor="userInput">Correo</label>
            <input type="text"
              className={errors.userName ? 'form-control is-invalid': 'form-control'} {...register("userName", { required: true })}
              id="userInput"
              aria-describedby="userNameHelp"
            />
            {errors.userName?.type === 'required' && <p className="invalid-feedback mb-0">Campo requerido</p>}
          </div>
          <div className={errors.password ? 'form-control is-invalid mb-4': 'form-control mb-4'}>
            <label htmlFor="passwordInput">Contraseña temporal</label>
            <input 
              type="password"
              className={errors.password ? 'form-control is-invalid': 'form-control'} {...register("password", { required: true })}
              id="passwordInput"
              aria-describedby="passwordInputHelp"
            />
              {errors.password?.type === 'required' && <p className="invalid-feedback mb-0">Campo requerido</p>}
          </div>
          <div className={errors.newPassword ? 'form-control is-invalid mb-4': 'form-control mb-4'}>
            <label htmlFor="passwordInput">Nueva contraseña</label>
            <input 
              type="password"
              className={errors.newPassword ? 'form-control is-invalid': 'form-control'} {...register("newPassword", { required: true })}
              id="newPasswordInput"
              aria-describedby="newPasswordInputHelp"
            />
              {errors.newPassword?.type === 'required' && <p className="invalid-feedback mb-0">Campo requerido</p>}
          </div>
          <Button
            variant="success"
            size="lg"
            className={errors.userName ? 'text-uppercase is-invalid mt-3 w-100': 'text-uppercase mt-3 w-100'}
            type="submit"
          >
            {isLoading &&
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            }
            {!isLoading && 'Iniciar sesión'}
          </Button>
          <VersionApp isLogin={true} />
        </form>
      </div>
    </main>
  );
};

export default ChangePasswordForm;