import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Button from 'react-bootstrap/Button';
import { apiRoutes } from "../../../api";
import Axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';

function CreateReportForm () {
  const [fechaInicio, setFechaInicio]= useState('')
  const [fechaFin, setFechaFin]= useState('')
  const [showMsgSuccess, setShowMsgSuccess] = useState(false);
  const [showMsgError, setShowMsgError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getReport = () =>{
    setIsLoading(true);
    Axios(
      {
        baseURL: process.env.REACT_APP_BASE_URL,
        method: 'GET', 
        url: apiRoutes.GET_REPORT_AGENCY.replace(':initDate', fechaInicio).replace(':endDate', fechaFin),
        responseType:'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@csi/token')}`,
          'x-apigw-api-id': process.env.REACT_APP_APIGW_ID,
          'Content-Type': 'application/json',
        },
        
      }
    ).then((response) => {
      var downloadLink = document.getElementById('downloadButton');
      downloadLink.href = window.URL.createObjectURL(new Blob([response.data],
        {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
      );
      downloadLink.download = 'ReporteRobotCSI';
      downloadLink.style.display = 'block';
      downloadLink.click();
      downloadLink.href = '';
      downloadLink.download = '';
      downloadLink.style.display = 'none';
      setIsLoading(false)
      setShowMsgSuccess(true);
    })
    .catch(() => {
      setShowMsgError(true)
      setIsLoading(false)
   });
  }

  const { 
      register, 
      handleSubmit, 
      reset,
      setValue,
      formState: { errors }
  } = useForm();
  const onSubmit = data => {
    setFechaInicio(data.desdeInput)
    setFechaFin(data.hastaInput)
  }

  useEffect(() => {
    if(fechaFin !== '' && fechaInicio !== ''){
      getReport()
      reset();
      // setShowMsgSuccess(true);
    }
  },[fechaFin, fechaInicio]);

  const today = new Date().toISOString().split('T')[0]; 

  useEffect(() => {
    if(fechaFin === '' && fechaInicio === ''){
      setValue('desdeInput', new Date().toISOString().split('T')[0]);
      setValue('hastaInput', new Date().toISOString().split('T')[0]);
    }
    var downloadLink = document.getElementById('downloadButton');
    downloadLink.style.display = 'none';
  },[])
  

  return (
    <>
    {showMsgSuccess && <Alert variant='success' onClose={() => setShowMsgSuccess(false)} dismissible>El reporte se generó exitosamente y comenzará su descarga en un momento</Alert>}
    {showMsgError && <Alert variant='danger' onClose={() => setShowMsgError(false)} dismissible>El reporte no se generó correctamente</Alert>}
    <main className="w-100 h-100 m-auto d-flex">
      <a id="downloadButton"></a>
      <div className="container">
        <form id="CreateAgency"  onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-12 col-md-6 mb-5">
                    <div className="form-control">
                        <label htmlFor="desdeInput">Desde</label>
                        <input type="date" max={today}  className={errors.desdeInput ? 'form-control is-invalid': 'form-control'} {...register("desdeInput", { required: true })} />
                        {errors.desdeInput?.type === 'required' && <p className="invalid-feedback mb-0">Campo requerido</p>}
                    </div>
                </div>

                <div className="col-12 col-md-6 mb-5">
                    <div className="form-control">
                        <label htmlFor="hastaInput">Hasta</label>
                        <input type="date" max={today}  className={errors.hastaInput ? 'form-control is-invalid': 'form-control'} {...register("hastaInput", { required: true })} />
                          {errors.hastaInput?.type === 'required' && <p className="invalid-feedback mb-0">Campo requerido</p>}
                    </div>
                </div>
          
            </div>
            <div className="row">
                <div className="col-md-12 d-flex justify-content-end">
                
                </div>
                <div className="col-md-12 d-flex justify-content-end">
                    <Button  variant="primary" size="lg" className="mt-3 text-uppercase" type="submit"
                      disabled={isLoading}>
                                              {isLoading &&
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      }
                      {!isLoading && 'Guardar'}
                      </Button>
                </div>
            </div>
        </form>
      </div>
    </main>
  </>
  
  );
}
export default CreateReportForm ;
