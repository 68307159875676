import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { useRequest } from "../hooks";
import { apiRoutes } from "../api";

const FilterComponent = ({ filterText, onFilter }) => (
  <div className="w-100 d-flex justify-content-between">
    <input
      width="200"
      id="search-filter"
      type="text"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    {localStorage.getItem('@csi/rol') === 'CONSULTA' ? '' :
        <Button
        className="btn btn-primary d-flex align-items-center"
        disabled={localStorage.getItem("@csi/rol") === "CONSULTA"}
      >
        <Link
          className=" text-light text-decoration-none d-flex align-items-center"
          to="/user/create"
        >
          <span className="icon-add-white-btn me-2"></span>Agregar Usuario
        </Link>
      </Button>
    }

  </div>
);

const ModalRemoveUser = ({ show, handleClose, confirmRemove }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar usuario</Modal.Title>
      </Modal.Header>
      <Modal.Body>¿Estás seguro de eliminar este usuario?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={confirmRemove}>
          Eliminar usuario
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const UserList = () => {
  const [filterText, setFilterText] = useState("");
  const [getUsersFn, { data, isLoading: isLoadingUsers }] = useRequest({
    url: `${apiRoutes.GET_USERS}?valor=${filterText}`,
    method: "GET",
  });
  const [idUserRemove, setIdRemoveUser] = useState("");
  const [removeUserFn, { data: responseRemove, isLoading: isLoadingRemove }] =
    useRequest({
      url: apiRoutes.REMOVE_USER.replace(":id", idUserRemove),
      method: "DELETE",
    });
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [users, setUsers] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const columns = [
    {
      name: "Agencia",
      selector: (row) => row.agency,
      sortable: true,
    },
    {
      name: "Nombre",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Rol",
      selector: (row) => row.role,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Estatus",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Operaciones",
      cell: (row) => (
        <>
          <Link
            className="btn btn-info btn-sm mx-1 fs-6 text-bold"
            to={`/user/edit/${row.id}`}
          >
            {localStorage.getItem("@csi/rol") === "CONSULTA"
              ? "Ver más"
              : "Editar"}
          </Link>
          {localStorage.getItem("@csi/rol") === "CONSULTA" ? (
            ""
          ) : (
            <Button
              disabled={localStorage.getItem("@csi/rol") === "CONSULTA"}
              className="mx-1 fs-6 text-bold"
              variant="danger"
              size="sm"
              onClick={() => {
                setIdRemoveUser(row.id);
                setShowModal(true);
              }}
            >
              Eliminar
            </Button>
          )}
        </>
      ),
    },
  ];

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    getUsersFn();
  }, [filterText]);

  useEffect(() => {
    if (data) {
      const usersSanitizate = data.map((user) => ({
        id: user.idUsuario,
        agency: user.rol === "ADMINISTRADOR" ? "" : user.nombreAgencia,
        name: user.nombre,
        role: user.rol,
        email: user.correo,
        status: user.activo ? "Activo" : "Inactivo",
      }));
      setUsers(usersSanitizate);
    }
  }, [data]);

  useEffect(() => {
    if (responseRemove) {
      getUsersFn();
    }
  }, [responseRemove]);

  const paginationComponentOptions = {
    rowsPerPageText: "Usuarios por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  return (
    <div className="container py-5">
      <div className="row mb-3 ps-2">
        <h1>Usuarios</h1>
      </div>
      <div className="datatable-wrapper">
        <DataTable
          className="table-striped"
          columns={columns}
          data={users}
          noDataComponent={"No se encontraron usuarios"}
          pagination
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          progressPending={isLoadingRemove || isLoadingUsers}
          progressComponent={<Spinner animation="border" />}
          paginationComponentOptions={paginationComponentOptions}
        />
      </div>
      <ModalRemoveUser
        show={showModal}
        handleClose={() => setShowModal(false)}
        confirmRemove={() => {
          removeUserFn();
          setShowModal(false);
        }}
      />
    </div>
  );
};

export default UserList;
