import { Outlet } from "react-router-dom";
import NavPrimaryAnon from "../components/navigation/anonymous";

const pageAnonymous = () => {
  return (
    <>
      <NavPrimaryAnon />
      <Outlet />
    </>
  )
};

export default pageAnonymous;
