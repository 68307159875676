import { useParams } from "react-router-dom";
import UserCreateForm from "../components/forms/user/create-user-form";

const UserCreatePage = () => {
  const { id } = useParams();
  return (
    <div className="container py-5">
      {localStorage.getItem("@csi/rol") === "CONSULTA" ? (
        <div className="row mb-3 ps-2">
          <h1>Información de Usuario</h1>
        </div>
      ) : (
        <div className="row mb-3 ps-2">
          <h1>{id ? "Editar" : "Crear"} Usuario</h1>
        </div>
      )}

      <UserCreateForm></UserCreateForm>
    </div>
  );
};

export default UserCreatePage;
