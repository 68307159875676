import { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Button from 'react-bootstrap/Button';
import { useRequest } from '../../../hooks';
import { apiRoutes } from "../../../api";
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import { v4 as uuidv4 } from 'uuid';

function CreateAgencyForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showMsgSuccess, setShowMsgSuccess] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [showMsgError, setShowMsgError] = useState(false);
  const [inputFields, setInputFields] = useState([])
  // const [claseNal, setClaseNal]= useState('')
  // const [claseInt, setClaseInt]= useState('')
  // const [origin, setOrigin]= useState('')
  const [classError, setClassError]=useState(false)
  const [saveUserFn, { data: responseUser, isLoading: isLoadingAgencies, error: errorSubmit } ] = useRequest({
    url: id ? apiRoutes.EDIT_AGENCY : apiRoutes.CREATE_AGENCY,
    method: id ? 'PUT' : 'POST',
  });
  const [getUserFn, { data: user, isLoading: isLoadingUser } ] = useRequest({
    url: apiRoutes.GET_AGENCY_BY_ID.replace(':id', id),
    method: 'GET',
  });

  const { 
    register, 
    handleSubmit, 
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  
  const rol = localStorage.getItem('@csi/rol'); 

  useEffect(() => {
    if (id) {
      getUserFn();
    }
  }, [id]);

  useEffect(() => {
    if(user ){
      setInputFields(user.rutasExcep.map((route) => ({id: route.idRutaExcepcion})))
      setValue('idAgencia', user.idAgencia)
      setValue('agenciaInput', user.agencia);
      setValue('iataInput', user.iata);
      setValue('convenioInput', user.convenio);
      setValue('vigenciaInput', new Date(user.fechaVigencia).toISOString().substr(0, 10));
      setValue('descripcion','descripcion');
      setValue('convenioInput', user.convenio);
      setValue('claseNacionalInput', user.clases[0]?.claseRutaNac);
      setValue('claseInternacionalInput', user.clases[0]?.claseRutaInt);
    }
    
  },[user]);
  useEffect(() => {
    if(user ){
      setInputFields(user.rutasExcep.map((route) => ({id: route.idRutaExcepcion})))
      user.rutasExcep.forEach(route => {
        setValue(`origen-${route.idRutaExcepcion}`, route.origen);
        setValue(`destino-${route.idRutaExcepcion}`, route.destino);
        setValue(`mercado-${route.idRutaExcepcion}`, route.mercado);
        setValue(`clase-${route.idRutaExcepcion}`, route.clase);
      })
    }

  },[user]);  

  useEffect(() => {
    setIsLoading(isLoadingAgencies || isLoadingUser);
  },[isLoadingAgencies, isLoadingUser])

  const getRoutesData = (data) => {
    return inputFields.map(idObj => {
      const dataFilter =  Object.keys(data).filter(key=> key.includes(idObj.id))
      const response = {}
      dataFilter.forEach(value=>{
        response[value.split('-')[0]]= data[value]
      })
      return response;
    })
  }

  const onSubmit = data => {
    const nacional = data.claseNacionalInput
    const internacional = data.claseInternacionalInput
    const nacionalLength = ( nacional || '').length
    const internacionalLength = (internacional || '').length
    if( nacionalLength === 0 && internacionalLength === 0
      && getRoutesData(data).length === 0 ){
        
        setClassError(true)
        setShowMsgSuccess(false);  
      }else{
        let dataUser = {
          idAgencia: data.idAgencia,
          iata: data.iataInput,
          agencia: data.agenciaInput,
          convenio: data.convenioInput,
          fechaVigencia: data.vigenciaInput,
          descripcion: 'descripcion de prueba',
          clases: [{
            idClase: data.idClase,
            claseRutaNac: data.claseNacionalInput ? data.claseNacionalInput : null,
            claseRutaInt: data.claseInternacionalInput ? data.claseInternacionalInput : null
          }],
          rutasExcep: getRoutesData(data)
        }
        if (id) {
          dataUser = {
            ...dataUser}
        }
        saveUserFn(dataUser);
      }


    // if( data.claseNacionalInput.length === 0 && data.claseInternacionalInput.length === 0
    //   && getRoutesData(data).length === 0 ){
    //   console.log('ESTA VACIO')
    //   setClassError(true)
    //   setShowMsgSuccess(false);
    // }else{
    //   setClassError(false)
    // }
  }

  const handleAddFields = () => {
    setInputFields([...inputFields,
      {id: uuidv4()}])
    setClassError(false)
  }
  const  handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map(i => {
      if(id === i.id) {
        i[event.target.name] = event.target.value
      }
      return i;
    })
    setInputFields(newInputFields);
  }
  const handleRemoveFields = id => {
    const values  = [...inputFields];
    values.splice(values.findIndex(value => value.id === id), 1);
    setInputFields(values);
  }

  useEffect(() => {
    if (responseUser) {
      if(!user){
        reset();
      }
      setShowMsgSuccess(true);
      setShowMsgError(false);
    }
  }, [responseUser]);
  
  useEffect(() => {
    if (errorSubmit) {
      setMsgError('No fue posible guardar la agencia.');
      if (errorSubmit.status === 409) {
        setMsgError('No fue posible editar la agencia.');
      }
      setShowMsgError(true);
      setShowMsgSuccess(false);
    }
  }, [errorSubmit]);


   return (
   <>
    {showMsgSuccess && <Alert variant='success' onClose={() => setShowMsgSuccess(false)} dismissible>Los datos de la agencia se guardaron correctamente</Alert>}
    {showMsgError && <Alert variant='danger' onClose={() => setShowMsgError(false)} dismissible>{msgError}</Alert>}
    {classError && <Alert variant='danger' onClose={() => setClassError(false)} dismissible>Asigna una clase, o bien, una ruta a esta agencia</Alert>}
    <main className="w-100 h-100 m-auto d-flex">
       
      <div className="container">
        <form id="CreateAgency" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
          <div className="col-md-6 mb-5">
            <div className={errors.iataInput ? 'form-control is-invalid': 'form-control'}>
                <label htmlFor="iataInput">IATA</label>
                <input
                  maxLength={8}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  type="text"
                  className={errors.iataInput ? 'form-control is-invalid': 'form-control'}
                  {...register("iataInput", {
                    required: true,
                    // minLength: 2,
                    maxLength: 8,
                    pattern: /^[0-9]+$/i,               
                  })}
                  
                  disabled={isLoading || rol === 'CONSULTA'}
                />
                {errors.iataInput?.type === 'pattern' && <p className="invalid-feedback mb-0">Este campo solo admite caracteres numéricos</p>}
                {errors.iataInput?.type === 'required' && <p className="invalid-feedback mb-0">Campo requerido</p>}
                {errors.iataInput?.type === 'maxLength'  && <p className="invalid-feedback mb-0">El campo debe tener entre 1 y 8 caracteres numéricos </p>}
            </div>
          </div>
          <div className="col-md-6 mb-5">
            <div className={errors.agenciaInput ? 'form-control is-invalid': 'form-control'}>
                <label htmlFor="agenciaInput">Agencia</label>
                <input
                
                type="text"
                className={errors.agenciaInput ? 'form-control is-invalid': 'form-control'}
                {...register("agenciaInput",{
                  required: true,
                  minLength: 3,
                  maxLength: 50
                })}
                
                disabled={isLoading || rol === 'CONSULTA'}
                />
                {errors.agenciaInput?.type === 'required' && <p className="invalid-feedback mb-0">Campo requerido</p>}
                {errors.agenciaInput?.type === 'maxLength' || 'minLength' && <p className="invalid-feedback mb-0">El campo debe tener entre 3 y 50 caracteres </p>}
            </div>
          </div>
          <div className="col-md-6 mb-5">
            <div className={errors.convenioInput ? 'form-control is-invalid': 'form-control'}>
                <label htmlFor="convenioInput">Convenio</label>
                <input
                
                type="text"
                className={errors.convenioInput ? 'form-control is-invalid': 'form-control'} {...register("convenioInput", {
                  required: true,
                  minLength: 3,
                  maxLength: 40
                })}
                
                disabled={isLoading || rol === 'CONSULTA'}
                />
                {errors.convenioInput?.type === 'required' && <p className="invalid-feedback mb-0">Campo requerido</p>}
                {errors.convenioInput?.type === 'maxLength' || 'minLength' && <p className="invalid-feedback mb-0">El campo debe tener entre 3 y 40 caracteres </p>}
            </div>
          </div>
          <div className="col-md-6 mb-5">
            <div className={errors.vigenciaInput ? 'form-control is-invalid': 'form-control'}>
                <label htmlFor="vigenciaInput">Vigencia</label>
                <input
                  type="date" 
                  min={'2000-01-01'}
                  className={errors.vigenciaInput ? 'form-control is-invalid': 'form-control'} 
                  {...register("vigenciaInput", {
                    required: true,
                  })}
                  disabled={isLoading || rol === 'CONSULTA'}
                />
                {errors.vigenciaInput?.type === 'required' && <p className="invalid-feedback mb-0">Campo requerido</p>}
            </div>
          </div>
          {/* <div className="col-md-6 mb-5"></div> */}
          <div className="col-md-6 mb-5">
            <div className={errors.claseNacionalInput ? 'form-control is-invalid': 'form-control'}>
                <label htmlFor="claseNacionalInput">Clase Nacional</label>
                <input
                  maxLength={1}
                  onKeyPress={(event) => {
                    if (!/[aA-zZ]/.test(event.key)) {
                      event.preventDefault();
                    }
                    setClassError(false)
                  }}        
                  type="text" 
                  disabled={isLoading || rol === 'CONSULTA'}
                  className={errors.claseNacionalInput ? 'form-control is-invalid': 'form-control'} {...register("claseNacionalInput",{
                  required: false,
                  // minLength: 1,
                  maxLength: 1,
                  pattern: /^[A-Za-z]+$/i,
                  })}
                  />
                
                {errors.claseNacionalInput?.type === 'required' && <p className="invalid-feedback mb-0">Campo requerido</p>}
                {errors.claseNacionalInput?.type === 'pattern' && <p className="invalid-feedback mb-0">Este campo solo admite 1 caracter alfabético</p>}
                {errors.claseNacionalInput?.type === 'maxLength' && <p className="invalid-feedback mb-0">Este campo solo admite 1 caracter alfabético </p>}
            </div>
          </div>
          <div className="col-md-6 mb-5">
            <div className={errors.claseInternacionalInput ? 'form-control is-invalid': 'form-control'}>
                <label htmlFor="claseInternacionalInput">Clase Internacional</label>
                <input 
                  maxLength={1}
                  onKeyPress={(event) => {
                    if (!/[aA-zZ]/.test(event.key)) {
                    event.preventDefault();
                    }
                    setClassError(false)
                  }}                    
                  type="text"
                  disabled={isLoading || rol === 'CONSULTA'}
                  className={errors.claseInternacionalInput ? 'form-control is-invalid': 'form-control'}
                  {...register("claseInternacionalInput",{
                  required: false,
                  // minLength: 1,
                  maxLength: 1, 
                  pattern: /^[A-Za-z]+$/i,
                  })}
                />
                {errors.claseInternacionalInput?.type === 'required' && <p className="invalid-feedback mb-0">Campo requerido</p>}
                {errors.claseInternacionalInput?.type === 'pattern' && <p className="invalid-feedback mb-0">Este campo solo admite 1 caracter alfabético</p>}
                {errors.claseInternacionalInput?.type === 'maxLength' && <p className="invalid-feedback mb-0">Este campo solo admite 1 caracter alfabético </p>}
            </div>
          </div>
          </div>
          <div className="excepcion-ruta">
            <p>Excepción por clases y/o ruta</p>
            { rol === 'CONSULTA' ? '' :
            <Button disabled={isLoading || rol === 'CONSULTA'} variant="outline-primary" onClick={handleAddFields}><span className="icon-add-btn"></span> Agregar Ruta</Button>
            }
            <div className="crearRuta-wrapper mt-5">
              {inputFields.map(inputField => (
                
                  <div key={inputField.id} style={{ display: "flex", alignItems: "center" }} className="ruta row justify-content-center align-middle d-flex flex-row mb-3">
                  <div className=" col  col-lg-2 mb-3">
                    <div className="col col-lg-2 mb-3"  >
                        <label htmlFor="origenInput">Origen</label>
                        <input
                            disabled={isLoading || rol === 'CONSULTA'}
                            name="origenInput"
                            onChange={event => handleChangeInput(inputField.id, event)}
                            type="text"
                            required
                            maxLength={3}
                            onKeyPress={(event) => {
                              if (!/[aA-zZ]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            {...register(`origen-${inputField.id}`, {
                                required: true,
                              })}
                        />
                        
                        </div>
                    </div>
                    <div className="col col-lg-2 mb-3">
                    <div className="col col-lg-2 mb-3" >
                        <label htmlFor="claseInput">Destino</label>
                        <input
                            disabled={isLoading || rol === 'CONSULTA'}
                            name="destinoInput"
                            onChange={event => handleChangeInput(inputField.id, event)} 
                            type="text"
                            required
                            maxLength={3}
                            onKeyPress={(event) => {
                              if (!/[aA-zZ]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            {...register(`destino-${inputField.id}`, {
                                required: true,
                                minLength: 3,
                                maxLength: 3                
                              })}
                        />
                        </div>
                    </div>
                    <div className="col-lg-2 mb-3 ">
                    <div className="col-lg-2 mb-3">
                        <label htmlFor="claseInput">Clase</label>
                        <input
                            disabled={isLoading || rol === 'CONSULTA'}
                            name="claseInput"                            
                            onChange={event => handleChangeInput(inputField.id, event)} 
                            type="text"
                            required
                            maxLength={1}
                            onKeyPress={(event) => {
                              if (!/[aA-zZ]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            {...register(`clase-${inputField.id}`, {
                                required: true,
                                minLength: 1,
                                maxLength: 1                
                              })}
                        />
                        </div>
                    </div>
                    <div className="col-lg-3 mb-3">
                        
                        <Form.Group
                            className="form-control" name="mercadoInput" >
                            <Form.Label>Tipo</Form.Label>
                            <Form.Select
                            disabled={isLoading || rol === 'CONSULTA'}
                            {...register(`mercado-${inputField.id}`, { 
                              required: true,
                              minLength: 1,
                              maxLength: 1
                            })}
                           onKeyPress={(event) => {
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
      }}
                            className="form-control" aria-label="Default select example">
                                <option value="N">Nacional</option>
                                <option value="I">Internacional</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-lg-3 mb-3">
                            <Button
                            disabled={isLoading || rol === 'CONSULTA'} 
                            variant="link" 
                            size="lg" 
                            className="ps-2"
                            onClick={() => handleRemoveFields(inputField.id)}>
                            <span className="icon-remove-item"></span></Button>
                    </div>
                </div>
                )
                
              )}
            </div>
          </div>
          <div className='row'>
            <div className="col-md-12 d-flex justify-content-end">
            {localStorage.getItem("@csi/rol") === "CONSULTA" ? (
                  <Button
                    variant="light"
                    size="lg"
                    className="mt-3 text-uppercase"
                    onClick={() => navigate("/agency/list")}
                  >
                 {isLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    {!isLoading && "Volver"}
                  </Button>
                ) : (
                  <Button
                    variant="light"
                    size="lg"
                    className="mt-3 text-uppercase"
                    onClick={() => navigate("/agency/list")}
                  >
                    Cancelar
                  </Button>
                )}

                {localStorage.getItem("@csi/rol") === "CONSULTA" ? (
                  ""
                ) : (
                  <Button
                    variant="primary"
                    size="lg"
                    className="mt-3 text-uppercase"
                    type="submit"
                    disabled={isLoading || rol === "CONSULTA"}
                  >
                    {isLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    {!isLoading && "Guardar"}
                  </Button>
                )}
            </div>
          </div>
        </form>
      </div>
      
    </main>

  </>
  
  );
}
export default CreateAgencyForm;

