const initialState = {
  user: {
    idToken: '',
    accessToken: '',
    perfil: '',
    refreshToken: '',
  },
  isLogged: false,
}

const authReducer = (
  prevState,
  action,
) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        user: {
          ...prevState.user,
          ...action.user,
        },
        isLogged: true,
      };
    case 'LOGOUT':
      return initialState;
  }
}

export {
  authReducer,
  initialState,
};