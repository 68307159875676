import { useContext, useEffect, useMemo, useReducer } from "react";
import AuthContext from "../../contexts";
import { authReducer, initialState } from './authReducer';
import { setApiToken } from "../../api";

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const token = localStorage.getItem('@csi/token');
    const rol = localStorage.getItem('@csi/rol');
    if (token) dispatch({type: 'LOGIN', user: { idToken: token }});
    if (rol) dispatch({type: 'LOGIN', user: { perfil: rol }});
  }, []);

  const authContext = useMemo(() => ({
    login: (user) => {
      localStorage.setItem('@csi/token', user.idToken);
      localStorage.setItem('@csi/rol', user.perfil);
      setApiToken(user.idToken);
      dispatch({ type: 'LOGIN', user });
    },
    logout: () => {
      localStorage.removeItem('@csi/token');
      localStorage.removeItem('@csi/rol');
      dispatch({ type: 'LOGOUT' });
    },
    auth: state,
  }))

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);