import { useEffect } from "react";
import { Outlet, useNavigate }  from "react-router-dom";
import NavPrimary from "../components/navigation/primary";
import { useAuth } from "../hooks";

const Page = () => {
  const navigate = useNavigate();
  const { auth, logout } = useAuth();

  // useEffect(() => {
  //   const token = localStorage.getItem('@csi/token');
  //   const rol = localStorage.getItem('@csi/rol');
  //   if (!token || !rol || rol !== 'ADMINISTRADOR') {
  //     logout();
  //     navigate("/");
  //   }
  // }, []);

  return (
    <>
      <NavPrimary />
      <Outlet />
    </>
  )
};

export default Page;
