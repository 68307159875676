import { useEffect } from "react";
import { Outlet, useNavigate }  from "react-router-dom";
import PNRNavPrimary from "../components/navigation/pnr-primary";
import { useAuth } from "../hooks";

const PagePnr = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    const token = localStorage.getItem('@csi/token');
    const rol = localStorage.getItem('@csi/rol');
    if (!token || !rol || rol !== 'AGENCIAS') {
      logout();
      navigate("/");
    }
  }, []);
  
  return (
    <>
      <PNRNavPrimary />
      <Outlet />
    </>
  )
};

export default PagePnr;
