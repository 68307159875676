import { useEffect, useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { apiRoutes } from '../api';
import { useRequest } from '../hooks';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useNavigate } from "react-router-dom";

const FilterComponent = ({ filterText, onFilter}) => (
  
	<div className="w-100 d-flex justify-content-between">

		<input
      width="200"
			id="search-filter"
			type="text"
			aria-label="Search Input"
			value={filterText}
			onChange={onFilter}
		/>
    {localStorage.getItem('@csi/rol') === 'CONSULTA' ? '' :
        <Button
        className="btn btn-primary d-flex align-items-center" 
        disabled={ localStorage.getItem('@csi/rol') === 'CONSULTA'} >
        <Link
        className=" text-light text-decoration-none d-flex align-items-center" 
          to={"/agency/create"} >
            <span className="icon-add-white-btn me-2"></span>Agregar Agencia</Link>
        </Button>
    }


	</div>
);

const ModalRemoveUser = ({show, handleClose, confirmRemove}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar agencia</Modal.Title>
      </Modal.Header>
      <Modal.Body>¿Estás seguro de eliminar esta agencia?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={confirmRemove}>
          Eliminar agencia
        </Button>
      </Modal.Footer>
    </Modal>
  );
}


const AgencyList = () => {
  const dayjs = require('dayjs')
  dayjs.extend(utc)
  const [filterText, setFilterText] = useState('');
  const [getAgenciesFn, { data, isLoading: isLoadingUsers}] = useRequest({
    url:`${apiRoutes.GET_AGENCIES}?valor=${filterText}`,
    method: 'GET',
  });
  const [idAgencyRemove, setIdAgencyRemove] = useState('');
  const [removeAgencyFn, { data: responseAgencyRemove,  isLoading: isLoadingRemove }] = useRequest({
    url:apiRoutes.REMOVE_AGENCY.replace(':id', idAgencyRemove),
    method: 'DELETE',
  });
 
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [agencias, setAgencias] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const columns = [
    {
        name: 'IATA',
        selector: row => row.iata,
        sortable: true,
    },
    {
        name: 'Agencia',
        selector: row => row.agencia,
        sortable: true,
    },
    {
      name: 'Convenio',
      selector: row => row.convenio,
      sortable: true,
    },
    // {
    //   name: 'Descripción',
    //   selector: row => row.descripcion,
    //   sortable: true,
    // },
    {
      name: 'Vigencia',
      selector: row => dayjs.utc(row.fechaVigencia).format('DD/MM/YYYY'),
      sortable: true,
    },
    {
      name: 'Operaciones',
      cell: (agencias) => (
        <>
          <Link className="btn btn-info btn-sm mx-1 fs-6 text-bold" to={`/agency/edit/${agencias.idAgencia}`}>{localStorage.getItem('@csi/rol') === 'CONSULTA'? 'Ver más' : 'Editar'}</Link>
          {localStorage.getItem('@csi/rol') === 'CONSULTA'? '' :
          <Button disabled={ localStorage.getItem('@csi/rol') === 'CONSULTA'} 
          className="btn btn-danger btn-sm mx-1 fs-6 text-bold" 
          onClick={() => { setIdAgencyRemove(agencias.idAgencia); 
          setShowModal(true);}}>Eliminar</Button>
        }
        </>
      )
    }
];
	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);

  useEffect(() => {
    getAgenciesFn({valor: ''});
  }, [filterText])



  useEffect(() => {
    if (data) {
      const agenciasSanitizate = data.filter(agencias => agencias.activo).map(agencias => ({
        idAgencia: agencias.idAgencia,
        iataInput: agencias.iata,
        agenciaInput: agencias.agencia,
        convenioInput: 'con',
        vigenciaInput: agencias.fechaVigencia,
        descripcion: 'descripcion de prueba',
        clases: [{
          claseRutaNac: agencias.claseNacionalInput,
          claseRutaInt: agencias.claseInternacionalInput
        }],
        status: agencias.activo ? 'Activo' : 'Inactivo',
      }))
     
      setAgencias(agenciasSanitizate);
      
    }
  },[data]);

  useEffect(() => {
    if (responseAgencyRemove) {
      getAgenciesFn({valor: ''});
    }
  }, [responseAgencyRemove])

  const paginationComponentOptions = {
    rowsPerPageText: 'Agencias por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todas',
  };

  return (
    <div className="container py-5">
      <div className='row mb-3 ps-2'>
        <h1>Agencias</h1>
      </div>
      <div className="datatable-wrapper">
        <DataTable
          className="table-striped"
          columns={columns}
          data={data?data:[]}
          noDataComponent={'No se encontraron agencias'}
          pagination
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          progressPending={isLoadingRemove || isLoadingUsers}
          progressComponent={<Spinner animation="border" />}
          paginationComponentOptions={paginationComponentOptions}
          
        />
      </div>
      <ModalRemoveUser
        show={showModal}
        handleClose={() => setShowModal(false)}
        confirmRemove={() => {
          removeAgencyFn();
          setShowModal(false);
        }}
      />
    </div>
  );
};

/* 
 const AgencyList = () => {
  return <h1>Agency List</h1>;
}
*/
export default AgencyList;
  