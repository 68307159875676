import CreateReportForm from "../components/forms/report/create-report-form";
const ReportCreate = () => {
    return (
      <div className="container py-5">
        <div className="row mb-3 ps-2">
          <h1>Crear Reporte</h1>

        </div>
        <CreateReportForm></CreateReportForm>
      </div>
    );
  };
  
  export default ReportCreate;
  