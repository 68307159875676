import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import { useRequest } from "../../../hooks";
import { apiRoutes } from "../../../api";

const UserCreateForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [optAgencies, setOptAgencies] = useState([
    { value: "", label: "Selecciona una agencia" },
  ]);
  const [valueAgency, setValueAgency] = useState({ value: null, label: "" });
  const [hasErrorAgency, setHasAgencyError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMsgSuccess, setShowMsgSuccess] = useState(false);
  const [showMsgError, setShowMsgError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [getAgenciesFn, { data: agencies, isLoading: isLoadingAgencies }] =
    useRequest({
      url: apiRoutes.GET_AGENCIES,
      method: "GET",
    });
  const [
    saveUserFn,
    { data: responseUser, isLoading: isLoadingSave, error: errorSave },
  ] = useRequest({
    url: id ? apiRoutes.EDIT_USER : apiRoutes.CREATE_USER,
    method: id ? "PUT" : "POST",
  });
  const [getUserFn, { data: user, isLoading: isLoadingUser }] = useRequest({
    url: apiRoutes.GET_USER_BY_ID.replace(":id", id),
    method: "GET",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm();
  const watchRol = watch("rol");
  const rol = localStorage.getItem("@csi/rol");

  useEffect(() => {
    if (id) {
      getUserFn();
    }
  }, [id]);

  useEffect(() => {
    if (user) {
      setValueAgency({
        value: user.idAgencia,
        label: user.nombreAgencia,
      });
      setValue("firstName", user.nombre);
      setValue("email", user.correo);
      setValue("rol", user.rol);
      setValue("status", user.activo ? "activo" : "inactivo");
    }
  }, [user]);

  useEffect(() => {
    setIsLoading(isLoadingAgencies || isLoadingUser || isLoadingSave);
  }, [isLoadingAgencies, isLoadingUser, isLoadingSave]);

  const onSubmit = (data) => {
    if (
      data.rol === "ADMINISTRADOR" ||
      data.rol === "CONSULTA" ||
      (data.rol === "AGENCIAS" && !!valueAgency.value)
    ) {
      setHasAgencyError(false);
      let dataUser = {
        correo: data.email,
        rol: data.rol,
        nombre: data.firstName,
        activo: data.status === "activo",
        idAgencia: valueAgency.value,
      };
      if (id) {
        dataUser = {
          ...dataUser,
          idUsuario: user.idUsuario,
          // remover después
          idUsuUltModif: 1,
        };
      }
      saveUserFn(dataUser);
    } else {
      setHasAgencyError(true);
    }
  };

  useEffect(() => {
    getAgenciesFn({ valor: "" });
  }, []);

  useEffect(() => {
    if (agencies) {
      const agenciesSanitizate = agencies
        .filter((agency) => agency.activa)
        .map((agency) => ({
          value: agency.idAgencia,
          label: `${agency.iata} ${agency.agencia}/ ${agency.convenio}`,
        }));
      setOptAgencies(optAgencies.concat(agenciesSanitizate));
    }
  }, [agencies]);

  useEffect(() => {
    if (responseUser) {
      if (!user) {
        reset();
        setValueAgency({ value: null, label: "" });
      }
      setShowMsgSuccess(true);
      setShowMsgError(false);
    }
  }, [responseUser]);

  useEffect(() => {
    if (errorSave) {
      setMsgError("No se pudieron guardar los datos correctamente");
      if (errorSave.status === 409) {
        setMsgError("Ya existe un usuario con este correo");
      }
      setShowMsgError(true);
      setShowMsgSuccess(false);
    }
  }, [errorSave]);

  return (
    <>
      {showMsgSuccess && (
        <Alert
          variant="success"
          onClose={() => setShowMsgSuccess(false)}
          dismissible
        >
          Los datos del usuario se guardaron correctamente
        </Alert>
      )}
      {showMsgError && (
        <Alert
          variant="danger"
          onClose={() => setShowMsgError(false)}
          dismissible
        >
          {msgError}
        </Alert>
      )}
      <main className="w-100 h-100 m-auto d-flex">
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-6 mb-5">
                <div
                  className={
                    errors.firstName
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                >
                  <label htmlFor="firstNameInput">Nombre</label>
                  <input
                    className={
                      errors.firstName
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    disabled={isLoading || rol === "CONSULTA"}
                    {...register("firstName", { required: true })}
                  />
                  {errors.firstName?.type === "required" && (
                    <p className="invalid-feedback mb-0">Campo requerido</p>
                  )}
                </div>
              </div>
              <div className="col-6 mb-5">
                <div
                  className={
                    errors.email ? "form-control is-invalid" : "form-control"
                  }
                >
                  <label htmlFor="emailInput">Correo Electrónico</label>
                  <input
                    className={
                      errors.email ? "form-control is-invalid" : "form-control"
                    }
                    disabled={isLoading || rol === "CONSULTA"}
                    {...register("email", {
                      required: true,
                      pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                    })}
                  />
                  {errors.email?.type === "required" && (
                    <p className="invalid-feedback mb-0">Campo requerido</p>
                  )}
                  {errors.email?.type === "pattern" && (
                    <p className="invalid-feedback mb-0">
                      Ingresa un email válido
                    </p>
                  )}
                </div>
              </div>
              <div className="col-6 mb-5">
                <Form.Group className="form-control">
                  <Form.Label>Rol</Form.Label>
                  <Form.Select
                    {...register("rol", { required: true })}
                    aria-label="Default select example"
                    className="form-control"
                    disabled={isLoading || rol === "CONSULTA"}
                  >
                    <option value="ADMINISTRADOR">Administrador</option>
                    <option value="AGENCIAS">Agencia</option>
                    <option value="CONSULTA">Consulta</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-6 mb-5">
                <Form.Group className="form-control">
                  <Form.Label>Estatus</Form.Label>
                  <Form.Select
                    {...register("status", { required: true })}
                    aria-label="Default select example"
                    className="form-control"
                    disabled={isLoading || rol === "CONSULTA"}
                  >
                    <option value="activo">Activo</option>
                    <option value="inactivo">Inactivo</option>
                  </Form.Select>
                </Form.Group>
              </div>
              {watchRol === "AGENCIAS" && (
                <div className="col-6 mb-5">
                  <div
                    className={
                      hasErrorAgency
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  >
                    <label htmlFor="emailInput">Agencias</label>
                    <Select
                      className={hasErrorAgency && "form-control is-invalid"}
                      classNamePrefix="react-select"
                      options={optAgencies}
                      onChange={(opt) => setValueAgency(opt)}
                      value={valueAgency}
                      isDisabled={isLoading || rol === "CONSULTA"}
                    />
                  </div>
                  {hasErrorAgency && (
                    <p className="invalid-feedback mb-0">
                      Debes seleccionar una agencia
                    </p>
                  )}
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-md-12 d-flex justify-content-end">
                {localStorage.getItem("@csi/rol") === "CONSULTA" ? (
                  <Button
                    variant="light"
                    size="lg"
                    className="mt-3 text-uppercase"
                    onClick={() => navigate("/user/list")}
                  >
                 {isLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    {!isLoading && "Volver"}
                  </Button>
                ) : (
                  <Button
                    variant="light"
                    size="lg"
                    className="mt-3 text-uppercase"
                    onClick={() => navigate("/user/list")}
                  >
                    Cancelar
                  </Button>
                )}

                {localStorage.getItem("@csi/rol") === "CONSULTA" ? (
                  ""
                ) : (
                  <Button
                    variant="primary"
                    size="lg"
                    className="mt-3 text-uppercase"
                    type="submit"
                    disabled={isLoading || rol === "CONSULTA"}
                  >
                    {isLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    {!isLoading && "Guardar"}
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export default UserCreateForm;
