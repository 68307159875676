import {  useEffect } from "react"; 
import { useRequest } from '../hooks';
import { apiRoutes } from "../api";
import { VersionNo } from "../components/version-app";

export const ManagementInfo = () => {
  const [managementInfo, { data, error: errorLogin, isLoading }] = useRequest({
    url: apiRoutes.MANAGEMENT_INFO,
    method: 'GET',
  });

  useEffect(() => {
    managementInfo({});
  }, []);

  return (
    <>
      <div>
        <textarea 
              className="textareaManagment"
              rows="25"
              value={isLoading? "Cargando...": JSON.stringify(data, null, '\t').replace('VUI-0.0', VersionNo)} 
              readOnly="readonly" 
          />
      </div>
    </>
  )
}

