export default {
  GET_AGENCIES: '/admin/agencias',
  GET_AGENCY_BY_ID: '/admin/agencias/:id',
  CREATE_AGENCY: '/admin/agencias',
  DELETE_AGENCY: '/admin/agencias/eliminar',
  REMOVE_AGENCY: '/admin/agencias/:id',
  EDIT_AGENCY: '/admin/agencias',
  UPDATE_AGENCY: '/admin/agencias/modificar',
  GET_REPORT_AGENCY: '/admin/agencias/reporte?fechaInicio=:initDate&fechaFin=:endDate',
  SELECT_AGENCY: '/admin/agencias/seleccionar',
  LOGIN: '/acceso/autenticar',
  CREATE_USER: '/admin/usuarios',
  GET_USERS: '/admin/usuarios',
  GET_USER_BY_ID: '/admin/usuarios/:id',
  EDIT_USER: '/admin/usuarios',
  REMOVE_USER: '/admin/usuarios/:id',
  CHANGE_CRED: '/acceso/modificar',
  RESET_CRED: '/acceso/restablecer',
  CONFIRM_CRED: '/acceso/confirmar',
  CHANGE_CLASE: '/agencias/cambiarclase',
  CONFIRM_CLASE: '/agencias/cambiarclase?messageId=:messageId',
  MANAGEMENT_INFO: '/management/info'
}