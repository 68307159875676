import Axios from 'axios';
import noAuthorizedInterceptor from './interceptors';

const api = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

if (localStorage.getItem('@csi/token')) {
  api.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('@csi/token')}`;
}

const setApiToken = (token) => {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
}
api.defaults.headers.common['x-apigw-api-id'] = process.env.REACT_APP_APIGW_ID;
api.interceptors.response.use(
  noAuthorizedInterceptor().onFulfilled,
  noAuthorizedInterceptor().onRejected,
);

const apiRequest = async(params) => {
  let error = null;
  let response = null;
  try {
    response = await api(params);
    return { error, response: response?.data ? response.data : null };
  } catch(e) {
    error = {
      error: e,
      message: e.response?.data.message,
      status: e.response?.status,
    }
    return {error, response };
  }
}

export { api, apiRequest, setApiToken };
