import CreateAgencyForm from '../components/forms/agency/create-agency-form';
import { useParams } from 'react-router-dom';

const AgencyCreate = () => {
  const { id } = useParams();
  return (
    <div className="container py-5">
            {localStorage.getItem("@csi/rol") === "CONSULTA" ? (
        <div className="row mb-3 ps-2">
          <h1>Información de Agencia</h1>
        </div>
      ) : (
        <div className="row mb-3 ps-2">
          <h1>{id ? "Editar" : "Crear"} Agencia</h1>
        </div>
      )}
      <CreateAgencyForm></CreateAgencyForm>
    </div>
  )
};

export default AgencyCreate;
  