import { useCallback, useState } from 'react';
import { apiRequest } from '../api';

const useRequest = ({ url, method }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const requestFn = useCallback(
    (bodyOpts) => {
      setData(null);
      setError(null);
      setIsLoading(true);
      const requestParams = {
        method,
        url,
      };
      if (bodyOpts) {
        requestParams.data = bodyOpts;
      }
      return apiRequest(requestParams)
      .then((res) => {
        if (res.response !== null) setData(res.response);
        if (res.error) setError(res.error);
        setIsLoading(false);
      }).catch((e) => {
        setIsLoading(false);
        throw e;
      })
    },
    [url]
  );
  return [requestFn, { data, error, isLoading }];
}

export default useRequest;