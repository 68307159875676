import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PageAnonymous from './layouts/page-anonymous';
import Page from './layouts/page';
import PagePnr from './layouts/page-pnr';
import CSILogin from './pages/csi-login-page';
import PNRValidate from './pages/pnr-validate-page';
import AgencyList from './pages/list-agency-page';
import AgencyCreate from './pages/create-agency-page';
import UserList from './pages/list-user-page';
import UserCreate from './pages/create-user-page';
import ReportCreate from './pages/create-report-page';
import ChangePassword from './pages/change-password';
import ResetPassword from './pages/reset-password';
import { AuthProvider } from './hooks/useAuth';
import { ManagementInfo }  from './pages/management-info';
// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/HelveticaNeue/HelveticaNeue.css'
import './assets/scss/styles.scss';

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<PageAnonymous />}>
            <Route index element={<CSILogin />} />
            <Route path="change/password" element={<ChangePassword />} />
            <Route path="reset/password" element={<ResetPassword />} />
            <Route path="management/info" element={<ManagementInfo />} />
            <Route path="*" element={<CSILogin />} />
          </Route>
          <Route element={<PagePnr />}>
            <Route path="pnr/validate" element={<PNRValidate />} />
          </Route>
          <Route element={<Page />}>
            <Route path="agency/list" element={<AgencyList />} />
            <Route path="agency/create" element={<AgencyCreate />} />
            <Route path="agency/edit/:id" element={<AgencyCreate />} />
            <Route path="report/create" element={<ReportCreate />} />
            <Route path="user/list" element={<UserList />} />
            <Route path="user/create" element={<UserCreate />} />
            <Route path="user/edit/:id" element={<UserCreate />} />
          </Route>
         
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);