
export const VersionNo = () => {
    return ("1.0.9");
}

const VersionApp = ({isLogin}) => {
    return (
        <>
        {isLogin?
            <p className="mt-3 mb-3 text-muted text-center fs-6">
            Global Sales Support/TI Comercial <br />
            Todos los derechos reservados <br />
            &copy; Aeroméxico 2022 <br />
            <small>v{VersionNo()}</small>
            </p>
            :
            <span className="version">v{VersionNo()}</span>
        }
        </>
    );
}


export default VersionApp;

