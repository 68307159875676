const noAuthorizedInterceptor = () => ({
  onFulfilled: (response) => response,
  onRejected: (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('@csi/token');
      localStorage.removeItem('@csi/rol');
      window.location.replace('/');
    }
    return Promise.reject(error);
  }
});

export default noAuthorizedInterceptor;